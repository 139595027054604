/*###############################################################*/
/*############### DATEPICKER ####################################*/
/*###############################################################*/

.ui-widget {
    font-family: 'Quicksand', sans-serif;
}

.ui-widget.ui-widget-content {
    border: 1px solid #f0a72c;
}

.ui-widget-content {
    background: #fff;
    color: #6a6a6a;
}



.ui-widget-header {
    border: 1px solid #f0a72c;
    background: none; 
    color: #ffffff;
    font-weight: bold;
    color: #f0a72c;
}

.ui-datepicker th {
    font-weight: 600;
    /* padding: .7rem .3rem .5rem; */
}

.ui-datepicker td span, .ui-datepicker td a {
    text-align: center;
}

.ui-widget-header .ui-datepicker-next .ui-icon,
.ui-widget-header .ui-datepicker-prev .ui-icon {
    background-image: none;
    /* background-color: #6a6a6a; */
}

.ui-datepicker .ui-datepicker-next{
    right: 3px
}

.ui-datepicker .ui-datepicker-prev {
    left: 1px;
}

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
    top: 3.5px;
}

.ui-datepicker-next:after{
    font-family: "Font Awesome 5 Free";
    position: absolute;
    padding-left: 0.8rem;
    top: 12.5%;
    top:6%;
    right:27%;
    /* width: 19px; */
    /* height: 23px; */
    background-repeat: no-repeat;
    color: #f0a72c;
    font-size: 20px;
    content:"\f054";
}

.ui-datepicker-next:hover:after{
    color:#fff;
}

.ui-datepicker-prev:after{
    font-family: "Font Awesome 5 Free";
    position: absolute;
    padding-left: 0.8rem;
    top: 12.5%;
    top:6%;
    right:32%;
    /* width: 19px; */
    /* height: 23px; */
    background-repeat: no-repeat;
    color: #f0a72c;
    font-size: 20px;
    content:"\f053";
}


.ui-datepicker-prev:hover:after{
    color:#fff;
}

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus, .ui-button:hover, .ui-button:focus {
    border: none;
    background: #f0a72c;
    font-weight: 700;
    /* color: #c77405; */
    color: #fff;
   
}

.ui-datepicker .ui-datepicker-prev-hover, .ui-datepicker .ui-datepicker-next-hover {
    top: 3.5px;
    cursor: pointer;
    
}

.ui-state-default, 
.ui-widget-content .ui-state-default, 
.ui-widget-header .ui-state-default, 
.ui-button, 
html .ui-button.ui-state-disabled:hover, 
html .ui-button.ui-state-disabled:active {
    border: none;
    background: none;
    font-weight: 400;
    color: #6a6a6a;
}



.ui-widget-content .ui-state-hover{
    transition: all .4s ease-in-out;
}

.ui-widget-content .ui-state-hover{
    border: 1 px solid #f0a72c;
}

.ui-widget-content .ui-datepicker-calendar .ui-state-active,
.ui-widget-content .ui-datepicker-calendar .ui-state-hover{
    background: #f0a72c;
    color:#000;
}

.ui-widget-content .ui-datepicker-today a{
    color:#000;
    font-weight: bold;
}

/*###############################################################*/
/*############# FIM DATEPICKER ##################################*/
/*###############################################################*/
