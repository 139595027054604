.site-footer {
  width: 100%;
  // background-color:$whiteSmoke;
  background-color: $corBranco;
  position: relative;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.site-footer aside {
  width: 33%;
}

.site-footer .dados-contacto {
  margin: 0;
}

.site-footer .dados-contacto h2 {
  color: $mainColor;
  margin: 0;
  padding: 0 0 1.5rem;
}

.site-footer .logo-rodape {
  text-align: center;
}

.site-footer .logo-rodape img {
  width: 35%;
}

.right-section {
  width: 80%;
  text-align: left;
  margin-left: 20%;
}

.rodape-redes-sociais h4 {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 300;
  padding-bottom: 1rem;
  color: $cinzentoEscuro;
}


.rede{
	display: inline;
	padding: 0 5px;
}

.rede a{
	text-decoration: none;
}

.rede svg:hover{
	cursor: pointer;
}

path{ 
	fill-opacity:0.8;
}

.rede svg:hover path{
	fill-opacity:1;
	
}