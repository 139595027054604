.galeria-page{
    margin-top: 7rem;
    padding-bottom: 4rem;
}

.grid-container-galeria {
    -moz-column-gap: .8rem;
    column-gap: 0.8rem;
    -moz-columns: 230px 5;
    columns: 230px 4;
    margin: 0 auto;
    width: 90%;
}

.galeria-main{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 7rem;
}

.galeria-main .galeria {
    width: 32%;
    padding: 1rem 0.5rem;
} 

.galeria-main .imagem-bg {
    height: 35vh;
    position: relative;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.galeria-main .filtro {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.20);
}

.galeria-main .imagem-bg p {
    color: #ffffff;
    position: relative;
    margin: 0;
    padding: 0 0 0.4rem 1rem;
}

/*lightcase*/
#lightcase-info{
    width: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: row-reverse;
    padding-top: 5px;
}

#lightcase-info #lightcase-sequenceInfo{
    width: 10%;
    text-align: right;
}

#lightcase-info #lightcase-caption{
    width: 90%;
    font-size: 1rem;
}