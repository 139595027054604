.navbar {
}

.navbar-nav {
  // align-items: center;
}

.navbar-front {
  position: fixed;
  z-index: 9999;
  left: 0;
  right: 0;
  padding: 1rem;
  align-items: flex-start;
  // transition: border-bottom .6s ease-in-out,background .6s ease-in-out,padding .6s ease-in-out;
  transition: all .6s ease-in-out;
  //   background-color: $whiteSmoke;
  // height: 120px;
}

.navbar.top-nav-collapse {
  padding: 0 1rem;
  border-bottom: 1px solid #f4f4f4;
  background-color: #fff;
  -webkit-box-shadow: 0px -4px 8px #000000;
  -moz-box-shadow: 0px -4px 8px #000000;
  box-shadow: 0px -4px 8px #000000;
  -webkit-backface-visibility: hidden;
  align-items: center;
  z-index: 1000;
  top: 0;
}

.navbar.top-nav-collapse .navbar-nav .btn{
  color:#4d4d4d;
}

.navbar-page {
  position: fixed;
  z-index: 9999;
  left: 0;
  right: 0;
  padding: 0 1rem;
  //   background-color: $whiteSmoke;
  background-color: $corBranco;
  height: 60px;
  // box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.navshadow {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.navbar .navbar-left {
  width: 30%;
  padding: .2rem 0 .2rem 3rem;
}

.navbar-brand .logo-icon {
  position: relative;
  //width: 30%;
  width: 20%;
  transition: width .6s ease-in-out;
}

// .navbar-brand .logo-icon.logo-icon-small{
//   width: 55px;
 
// }

.top-nav-collapse .logo-icon{
  width: 55px;
}



.navbar-brand .logo-texto {
  //width: 149px;
  //width: 75%;
  width: 50%;
  //opacity: 0;
  transition: width .6s ease-in-out;
}

.top-nav-collapse .logo-texto{
  opacity: 1;
  width: 149px;
}

// .navbar-brand .logo-texto.opacity-1{
//   opacity: 1;
// }
 


.navbar-brand .logo-icon-front {
  position: absolute;
  top: 6px;
  left: -10%;
  width: 50px;
  // opacity: 0;
}



.navbar-brand img{
  margin-right: .2rem;
}

.navbar-dark .navbar-brand{
  font-family: 'Caveat', cursive;
  color: $mainColor;
  font-size: 2rem;
}

.navbar .navbar-center-right {
  width: 75%;
  padding: 0.338rem 0;
}

// .navbar .navbar-center{
// 	width: 50%;
// 	justify-content: center;
// }

.navbar .navbar-right {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.navbar-nav .btn {
  padding: 0.475rem 1.5rem;
  color: $whiteSmoke;
  transition: all .6s ease-in-out;
}

.username {
  border: none;
  color: #fff;
}

.dropdown-toggle::after {
  margin-left: 1em;
}

.navbar-nav .dropdown-toggle {
  padding-right: 1rem;
}

.navbar-user {
  vertical-align: text-bottom;
}

.custom-logo-big {
  position: absolute;
  width: 25%;
}

.custom-logo-big img {
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;
  left: 3.1rem;
}

.nav.item .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0;
  content: "";
  border-top: 0.6em solid;
  border-right: 0.6em solid transparent;
  border-bottom: 0;
  border-left: 0.6em solid transparent;
  color: #000;
}

.nav-item-reserva{
  margin-left: 3rem;
  border: 2px solid $mainColor;
}
