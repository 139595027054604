/*##################################################################*/
/*##################  LANDING  #####################################*/
/*##################################################################*/
.slick-slide:focus {
  outline: none;
}

.slides-setas {
  position: absolute;
  width: 100%;
  // top: 120px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.slides-dots {
  position: absolute;
  left: 50%;
  bottom: 1%;
  // padding: 0 0 20px;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  z-index: 999;
}

.slides-seta-esquerda {
  position: absolute;
  top: 50%;
  left: 2%;
  width: 65px;
  height: 65px;
  cursor: pointer;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background: url("../../img/icon/slides-seta-esquerda.png") center center
    no-repeat;
}

.slides-seta-direita {
  position: absolute;
  top: 50%;
  right: 2%;
  width: 65px;
  height: 65px;
  cursor: pointer;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background: url("../../img/icon/slides-seta-direita.png") center center
    no-repeat;
}

.slides-main .slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slides-main .slick-dots {
  text-align: center;
  margin: 0 0 2rem;
  padding: 0;
}

.slides-main .slick-dots {
  text-align: center;
  margin: 0 0 2rem;
  padding: 0;
}

.slides-main .slick-dots {
  text-align: center;
  margin: 0 0 2rem;
  padding: 0;
}

.slides-main .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slides-main .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #fff;
}

.slides-main .slick-dots li button:before {
  font-family: "slick";
  font-size: 50px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*##################################################################*/
/*################  FIM LANDING  ###################################*/
/*##################################################################*/

/*##################################################################*/
/*##################  PRODUTOS  ####################################*/
/*##################################################################*/

.slick-slide:focus {
  outline: none;
}

.outros-produtos {
  position: relative;
  z-index: 9999;
}

.produtos-slide {
  padding: 0 2rem;
}

.slick-slide img {
  display: block;
}

.produtos-slide img {
  width: 100%;
}

.slides-produtos-setas {
  position: absolute;
  width: 100%;
  top: 120px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.slides-produtos-dots {
  position: absolute;
  left: 50%;
  bottom: 1%;
  // padding: 0 0 20px;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  z-index: 999;
}

.slides-produtos-seta-esquerda {
  position: absolute;
  top: 39%;
  left: 1%;
  width: 65px;
  height: 65px;
  cursor: pointer;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background: url("../../img/icon/slides-produtos-seta-esquerda.png") center
    center no-repeat;
}

.slides-produtos-seta-direita {
  position: absolute;
  top: 39%;
  right: 1%;
  width: 65px;
  height: 65px;
  cursor: pointer;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background: url("../../img/icon/slides-produtos-seta-direita.png") center
    center no-repeat;
}

.outros-produtos-main .slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.outros-produtos-main .slick-dots {
  text-align: center;
  margin: 0;
  padding: 0;
}

.outros-produtos .slick-dots {
  text-align: center;
  margin: 0 0 2rem;
  padding: 0;
}

.outros-produtos .slick-dots {
  text-align: center;
  margin: 0 0 2rem;
  padding: 0;
}

.outros-produtos-main .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.outros-produtos-main .slick-dots li.slick-active button:before {
  opacity: 1;
  color: $mainColor;
}

.outros-produtos-main .slick-dots li button:before {
  font-family: "slick";
  font-size: 50px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*##################################################################*/
/*################  FIM PRODUTOS  ##################################*/
/*##################################################################*/
