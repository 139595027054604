
.intro-foto {
  height: 100vh;
  position: relative;
}

.intro-foto .landing-page-content .landing-top p {
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: .15em;
  text-align: center;
  text-transform: none;
}

.intro-foto .landing-page-content {
  color: #fff;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 59%;
  transform: translate(-50%,-50%);
  width: 50%;
  z-index: 999;
}



//landing 2

.landing-page {
  position: relative;
  width: 100%;
  // height: 100vh;
  // padding-top:120px;
  // height: calc(100vh - 120px);
}

.filtro-landing-page {
  position: absolute;
  width: 100%;
  // height: calc(100% - 120px);
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 9;
}

.landing-page-content {
  /*width: 100%;*/
  position: absolute;
  //   top: 50%;
  left: 50%;
  top: 60%;
  /*padding: 30px;*/
  color: $corBranco;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 999;

  width: 50%;
}

.landing-page-content .landing-top h1 {
  font-size: 6rem;
  font-weight: 700;
  text-transform: uppercase;
  color: $mainColor;
  font-family: 'Caveat', cursive;
  letter-spacing: 3.5px;
}

.landing-page-content .landing-top {
  padding: 2rem;
}

.landing-page-content .landing-bottom {
  padding: 2rem;
}

.landing-page-content .landing-bottom .btn-amarelo{
  width: 50%;
}

.landing-page-content .landing-top p {
  //font-size: 1.8rem;
  //font-size: 5rem;
  letter-spacing: 0.15em;
    text-transform: uppercase;
    text-align: center;
    font-weight: 300;
    font-size: 2.3rem;
}

.slide {
  height: calc(100vh - 0px);
  position: relative;
}

.logo {
  width: 100%;
  position: absolute;
  top: 28%;
  left: 50%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 99;
}

.logo-site {
  width: 27%;
}
