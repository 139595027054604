@media only screen and (max-width: 1500px) {


  .quem-somos-main{
    padding: 5rem 3rem;
  }

  .quem-somos-texto{
    padding: 0 0 0 4rem;
  }

  .quem-somos-texto p{
    padding: 0 1rem;
    font-size: 1.3rem;
  }

  .comodidades{
    max-width: 60%;
  }

  .comodidades-content img{
    width: 40px;
  }

  .comodidades-content span{
    font-size: 1.2rem;
  }

  .front-reserva-piso aside div{
    font-size:2.4rem;
  }

  .front-reserva-piso aside .btn-amarelo{
    font-size: 1.6rem;
  }

  .seguranca-main{
    padding: 5rem 2rem;
  }

  .galeria-link .btn-amarelo{
    width: 25%;
  }

  .suite-content{
    //padding: 6rem 5rem;
    padding: 6rem 15rem;
  }
}



@media only screen and (max-width: 991px) {
  .navbar .navbar-right {
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    padding: 4rem 0;
    margin: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .semestre-2 {
    margin-top: 1rem;
  }

  .semestre-2 div:nth-child(1) {
    border-left: none;
  }
}
