.pagina {
  padding-top: 60px;
  background-color: $corBranco;
}

.pagina-title h1,
.pagina-title h2 {
  position: relative;
  text-align: center;
  color: $corBranco;
  font-weight: 500;
  /* padding-bottom: 90px; */
  font-size: 3.1rem;
  padding: 6rem 0 8rem;
}

.pagina-title h1:after,
.pagina-title h2:after {
  content: "";
  width: 5%;
  height: 3px;
  background-color: $corBranco;
  display: block;
  margin-top: 30px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.pagina-banner {
  position: relative;
}

.filtro-top-banner {
  background-color: #000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
}

.pagina-sub-title {
  text-align: center;
}

.pagina-sub-title h3 {
  margin: 0;
  padding: 3rem 0 2rem;
}

.texto-pagina {
  width: 60%;
  padding-bottom: 5rem;
  margin: 0 auto;
}

/*###############################################################*/
/*############## PÁGINA QUEM SOMOS ##############################*/
/*###############################################################*/

.quem-somos-content {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.quem-somos-timeline {
  width: 50%;
  background-color: $whiteSmoke;
}

.quem-somos-texto {
  width: 50%;
  padding: 0 4rem;
}

.quem-somos-texto p {
  /*padding-bottom: 4rem;*/
}

.quem-somos-texto img {
  max-width: 100%;
}

.quem-somos-timeline h2 {
  text-align: center;
  width: 100%;
  padding: 2rem 0;
}

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* contentor around content */
.contentor {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.contentor::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -12px;
  background-color: white;
  border: 4px solid $mainColor;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the contentor to the left */
.left {
  left: 0;
}

/* Place the contentor to the right */
.right {
  left: 50%;
}

/* Add arrows to the left contentor (pointing right) */
.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

/* Add arrows to the right contentor (pointing left) */
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

/* Fix the circle for contentors on the right side */
.right::after {
  left: -13px;
}

/* The actual content */
.content {
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width contentors */
  .contentor {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .contentor::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 15px;
  }

  /* Make all right contentors behave like the left ones */
  .right {
    left: 0%;
  }
}

/*###############################################################*/
/*############# FIM PÁGINA QUEM SOMOS ###########################*/
/*###############################################################*/

/*###############################################################*/
/*################ PÁGINA MARCAS ################################*/
/*###############################################################*/

.marcas-content-front {
}

.marca-aside {
}

/*###############################################################*/
/*############## FIM PÁGINA MARCAS ##############################*/
/*###############################################################*/

/*###############################################################*/
/*############### PÁGINA CONTACTOS ##############################*/
/*###############################################################*/

.contactos-content {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 0 3rem;
}

.contactos-equipa-main {
  width: 50%;
  padding: 0 2rem;
}

.contactos-mapa {
  width: 50%;
  background-color: $corBranco;
  padding: 0 2rem 0 2rem;
  position: relative;
}

.equipa-content {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.contacto-geral {
  width: 100%;
  padding: 0 0 0 2rem;
}

.contactos-cargo-block {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.contacto-cargo {
  width: 50%;
  //   width: 100%;
  //   display: flex;
  //   flex-wrap: wrap;
}

.contacto-cargo figure {
  margin: 0 0 0.4rem;
}

.contacto-geral h2 {
  color: $mainColor;
}

.contacto-geral h3 {
  color: $mainColor;
  font-size: 1.5rem;
  font-weight: 100;
  margin: 2rem 0 1rem;
}

.dados-contacto {
  margin-top: 2rem;
}

.dados-contacto figure {
  position: relative;
  display: flex;
  align-items: center;
}

.dados-contacto .morada-figure {
  align-items: flex-start;
}

.dados-contacto .contactos-morada,
.dados-contacto .contactos-telefone {
  padding-left: 10px;
  margin: 0.2rem 0;
}

.contactos-morada p {
  margin: 0;
}

#map {
  width: 100%;
  height: 400px;
}

.mapa {
  margin-top: 0;
}

.info-window {
  width: 220px;
}

.info-window h1 {
  font-size: 1.1rem;
  font-weight: 700;
}

.info-window h2 {
  font-size: 1rem;
}

.info-window-content {
  margin-top: 1rem;
}

.info-window-content p {
  font-size: 0.9rem;
  margin: 0;
}

.contacto-formulario {
  padding: 4rem 2rem;
}

.contacto-formulario h2 {
  color: #a5c61d;
  padding-bottom: 1rem;
}

/*###############################################################*/
/*############# FIM PÁGINA CONTACTOS ############################*/
/*###############################################################*/
