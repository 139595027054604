//@import url('https://fonts.googleapis.com/css2?family=Baloo+Thambi+2:wght@400;600;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500&display=swap");
//@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@600&display=swap');

* {
  box-sizing: border-box;
}

body {
  //background: #fafae4;
  // font-family: 'Baloo Thambi 2', cursive;
  font-family: "Nunito", sans-serif;
  //font-family: "Quicksand", sans-serif;
  font-weight: 300;
  background-color: $whiteSmoke;
  overflow-x: hidden;
}

h4 {
  margin: 0;
}

button:focus {
  outline: none;
} 

a,
a:hover {
  text-decoration: none;
}

input[type="checkbox"] {
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.titulo h1{
  width: 100%;
  text-align: center;
  color: $mainColor;
  font-family: 'Caveat', cursive;
  letter-spacing: 2.5px;
  padding: 0 0 5rem 0;
  font-size:3rem;
}

.titulo h3{
  width: 100%;
  text-align: center;
  color: $mainColor;
  font-family: 'Caveat', cursive;
  letter-spacing: 2.5px;
  padding: 0 0 5rem 0;
  font-size:3rem;
}

fieldset {
  border: 2px solid $mainColor;
  border-radius: 10px;
  padding: 3rem;
  height: 100%;
}

.main-body {
  min-height: 94vh;
}

.navbar-height {
}

.section-title h1,
.section-title h2 {
  text-align: center;
  color: $mainColor;
  font-weight: 300;
  padding-bottom: 90px;
  font-size: 2.5rem;
}

.section-title h1:after,
.section-title h2:after {
  content: "";
  width: 5%;
  height: 3px;
  background-color: $mainColor;
  display: block;
  margin-top: 30px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.margin-auto {
  margin: 0 auto;
}

.margin-auto30 {
  margin: 0 auto;
  width: 30%;
}

.margin-auto60 {
  margin: 0 auto;
  width: 60%;
}

.main-section {
  width: 100%;
  height: 100%;
}

.section-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.section-pad {
  padding: 7rem 2rem 0;
}

.section-default {
  padding: 2rem 2rem;
}

.section-default h1 {
  padding: 0 0 1rem;
}

.bold {
  font-weight: 600;
}

.hidden {
  display: none;
}

.d-block{
  display: block;
}

.errorvazio {
  background-color: #ca3845;
}

.checked-img {
  background: url("../../img/checked.png") center center no-repeat;
}

.uncheck-img {
  background: url("../../img/uncheck.png") center center no-repeat;
}

.entrar-img {
  cursor: pointer;
  background: url("../../img/entrar.png") center center no-repeat;
}

.sair-img {
  cursor: pointer;
  background: url("../../img/sair.png") center center no-repeat;
}

footer {
  min-height: 6vh;
  background-color: $footercolor;
}

.rodape {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 3rem 0 3rem;
}

.rodape a {
  color: #ffc107;
  text-decoration: none;
}

.rodape p {
  color: #fff;
  text-align: right;
  margin: 0;
}

.section-utilizadores {
  width: 80%;
  margin: 0 auto;
  padding: 4rem 0;
}

.section-utilizadores h2 {
  margin-bottom: 3rem;
  text-align: center;
}

.article-nova-pass {
  width: 60%;
  margin: 0 auto;
}

.btn-block {
  width: 100%;
}

.text-left {
  text-align: left;
}
