.suite-main {
    position: relative;
    width: 100%;
    background-color: #000;
    margin-top: 63.64px;
}

.background-suite {

    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.filtro{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.2);
}

.suite-titulo {
    padding: 7rem 1rem;
    position: relative;
    z-index: 9999;
}

.suite-titulo h3 {
    font-size: 3.1rem;
    width: 100%;
    color: #fff;
    text-align: center;
    font-weight: 300;
    margin: 0;
}


.suite-content{
    padding: 6rem 20rem;
    display: flex;
    flex-wrap: wrap;
}

.suite-content-left{
    //width: 65%;
    width: 96%;
    //background-color: $corBranco;
}

.suite-content-right{
    width: 35%;
    padding: 0 2rem;
    display: none;
}

.suite-content-left .suite-detalhes{
    
    
}

.suite-content-left .suite-detalhes-bloco{
    background-color: $corBranco;
    padding: 0 2rem 1rem;
    margin-top:1rem;
    border-radius: 5px;
}

.suite-content-left .icons-top{
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap:wrap;
    padding: 1rem 2.5rem;
}

.suite-content-left .icons-top{
    //text-align: center;
}

.suite-content-left .icons-top div{
    width: 50%;
    padding: 1rem 0;
}

.suite-content-left .icons-top div img{
    //padding: 1rem 0;
}

.suite-content-left .icons-top div span{
    vertical-align: sub;
    padding-left: 0.5rem;
    font-size:1.1rem;
}

.suite-content-left header h3, .suite-content-left h4{
    padding: 2rem 0;
}

.suite-content-left .icons-top img{
    width:1.5rem;
}

.foto-principal{
    width: 100%;
    border-radius: 5px;
}


//Parte direita ###########################

.suite-reserva-main{
    background-color: $corBranco;
    box-shadow: 0 5px 15px rgb(0 0 0 / 5%);
    border-radius: 5px;
    position: sticky;
    top: 6rem;
}

.suite-reserva-content{
    padding: 1.5rem;
}

.suite-reserva-content .preco-noite{
    background: #fff;
    bottom: 10px;
    color: #f0a72c;
    font-family: Caveat,cursive;
    font-size: 1.7rem;
    padding: 0 0.7rem;
    right: 10px;
    z-index: 999;
}

.suite-reserva-content .checkin-div, .suite-reserva-content .checkout-div, .suite-reserva-content .guest-div {
    position: relative;
}

.suite-reserva-content .calendar-icon:after, .suite-reserva-content .clear-date:after, .suite-reserva-content .guest-icon:after {
    background-repeat: no-repeat;
    color: #f0a72c;
    font-family: Font Awesome\ 5 Free;
    font-size: 20px;
    padding-left: 0.8rem;
    position: absolute;
    top: 11px;
}

.suite-reserva-content .form-control[readonly] {
    background-color: #fff;
    opacity: 1;
}

.suite-reserva-content input {
    font-size: .9rem;
    line-height: 2;
    padding: 0.7rem 1rem 0.7rem 2.4rem;
}

.suite-reserva-content .btn-amarelo{
    font-size: 1rem;
    line-height: 2;
    padding: 0.5rem 1rem 0.5rem 1rem;
}

.suite-reserva-content .guest-add-btn-content input{
    padding: 0;
}

.suite-reserva-content .clear-date:after {
    color: #dd5039;
    padding: 0;
    right: 10px;
    top: 12px;
}

.suite-reserva-content .guest-add{
    display: flex;
    padding-left: 1rem;
    align-items: center;
  }

.suite-reserva-content .result-block {
    align-items: center;
}

.suite-reserva-content .result-block input{
    padding: 0;
}

.reserva-result-content .taxa-servico-div{
    align-items: center;
}

.reserva-result-content .taxa-servico{
    padding: 0;
    border: none;
    font-weight: 700;
    text-align: right;
}

.reserva-result-content .total-noites-div{
    align-items: center;
}

.reserva-result-content .total-noites-div label{
    padding: 0 0.2rem;
}

.reserva-result-content .total-noites{
    padding: 0;
    border: none;
    
}

.reserva-result-content .total-noites-euros{
    padding: 0;
    border: none;
    font-weight: 700;
    text-align: right;
}

.reserva-result-content .total-euros-div{
    align-items: center;
}

.reserva-result-content .total-euros-div label, 
.reserva-result-content .total-euros-div span{
    font-weight: 700;
}

.reserva-result-content .total-euros{
    padding: 0;
    border: none;
    font-weight: 700;
    font-size: 1rem;
    text-align: right;
}






.grid-container {
    columns: 5 200px;
    column-gap: 0.8rem;
    width: 90%;
    margin: 0 auto;
}

.galeria-teste{
    width: 100%;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 50%);
    transition: all 0.25s ease-in-out;
    border-radius: 6px;
}

.galeria-teste a{
    margin: 0;
    overflow: hidden;
}

.galeria-teste figure{
position: relative;
}

.galeria-teste figure img{
    display: block;
    width: 100%;
    transition: 0.3s;
    border-radius: 6px;
}
