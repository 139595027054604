/*###############################################################*/
/*################ FRONT-RESERVA-FRONT ##########################################*/
/*###############################################################*/

#front-reserva-form {
  width: 100%;
  background-color: blanchedalmond;
}

.front-reserva-form-main {
  padding: 4.1rem 6rem 5.4rem 6rem;
  position: relative;
  display: none;
}

.front-reserva-form-main .row {
  align-items: flex-start;
}

article {
  position: relative;
}

.calendar-icon label,
.form-floating > .form-control {
  padding: 1rem 0.75rem 1rem 2.5rem;
}

.front-reserva-form-main .checkin-div,
.front-reserva-form-main .checkout-div,
.front-reserva-form-main .guest-div {
  position: relative;
}

.front-reserva-form-main #checkin,
.front-reserva-form-main #checkout,
.front-reserva-form-main .guest {
  padding: 0.7rem 1rem 0.7rem 2.4rem;
  font-size: 0.9rem;
  line-height: 2;
}

.front-reserva-form-main .form-control[readonly] {
  background-color: #fff;
  opacity: 1;
}

.front-reserva-form-main .form-control:focus {
  border-color: #f0a72c;
}

.front-reserva-form-main .calendar-icon:after,
.front-reserva-form-main .guest-icon:after,
.front-reserva-form-main .clear-date:after {
  font-family: "Font Awesome 5 Free";
  position: absolute;
  padding-left: 0.8rem;
  top: 11px;
  /* width: 19px; */
  /* height: 23px; */
  background-repeat: no-repeat;
  color: #f0a72c;
  font-size: 20px;
}

.calendar-icon:after {
  content: "\f073";
}

.clear-date {
  display: none;
  cursor: pointer;
}

.front-reserva-form-main .clear-date:after {
  top: 12px;
  right: 10px;
  padding: 0;
  color: #dd5039;
}

.clear-date:after {
  content: "\f057";
}

.guest-icon:after {
  content: "\f007";
}

.front-reserva-main-main label {
  display: block;
  padding: 0 0 0.1rem 0.3rem;
  transition: opacity 0.6s ease-in-out;
  font-size: 0.75rem;
}

.label-checkin,
.label-checkout {
  opacity: 0;
}

.opacity0 {
  opacity: 0;
  height: 0;
}

.opacity1 {
  opacity: 1;
}

.btn-amarelo {
  background-color: $mainColor;
  padding: 0.6rem 1rem;
  width: 100%;
  color: $corBranco;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  border: 4px solid $mainColor;
}

.btn-amarelo.focus,
.btn-amarelo:focus,
.btn-amarelo:hover {
  color: $mainColor;
  background-color: $corBranco;
  //border-color: #d39e00;
}

.guest-div-choice {
  align-items: center;
  justify-content: center;
}

.guest-div-choice {
  background: #fff;
  padding: 1rem;
  border-radius: 5px;
  margin-top: 0.3rem;
}

.guest-div-choice i {
  font-size: 0.8rem;
}

.guest-div-choice input {
  padding: 0;
  font-size: 1rem;
  line-height: 24px;
  border: none;
  text-align: center;
}

.guest-div-choice input:focus-visible {
  outline: none;
}

.guest-div-choice input.result-total-guests {
  font-weight: bold;
}

.guest-div-choice span {
  font-size: 1rem;
  line-height: 24px;
}

.guest-div-choice .menos,
.guest-div-choice .mais {
  font-weight: bold;
  font-size: 2rem;
  cursor: pointer;
  padding: 0;
  text-align: center;
  line-height: 24px;
}

.guest-div-choice .adultos-menos,
.guest-div-choice .criancas-menos,
.guest-div-choice .bebes-menos {
  border-color: lightgrey;
}

.guest-div-choice .adultos-menos path,
.guest-div-choice .criancas-menos path,
.guest-div-choice .bebes-menos path {
  stroke: lightgrey;
}

.guest-div-choice .menos:hover {
  background: $mainColor;
}

.guest-div-choice .mais:hover {
  background: $mainColor;
}

.limpar {
  margin-bottom: 0;
  text-align: right;
  cursor: pointer;
}

.ten-main .guest-add {
  display: flex;
  padding-left: 1rem;
  align-items: center;
}

.guest-add-btn-main {
  justify-content: end;
}

.guest-add-btn-content {
  justify-content: end;
  align-items: center !important;
}

.guest-add-btn-content .result {
  margin: 0 0.2rem;
}

.guest-add-btn-content button {
  border: 1px solid #000;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: inline-flex !important;

  justify-content: center !important;
}

.guest-add-btn-content button span {
  height: 22px !important;
  width: 22px !important;
  padding: 2px !important;
  //display: contents;
}

.guest-add-btn-content button path {
  stroke: #000;
}

.guest-add-btn-content button span {
}

.ten-main .result-block {
  align-items: center;
}

.result-block span {
  width: auto;
}

.result-block input {
  width: 15px;
}

/*###############################################################*/
/*################ FIM FRONT-RESERVA-FORM ######################################*/
/*###############################################################*/

/*###############################################################*/
/*############# QUEM-SOMOS ######################################*/
/*###############################################################*/

.quem-somos-main {
  display: flex;
  width: 100%;
  padding: 5rem 10rem;
  flex-wrap: wrap;
  align-items: center;
  background-color: $corBranco;
}

.quem-somos-img,
.quem-somos-texto {
  width: 50%;
}

.quem-somos-texto .titulo h1{
  padding: 0 0 3rem;
}

.quem-somos-img,
.quem-somos-texto span {
  color: $mainColor;
  font-weight: bold;
  //font-family: Caveat,cursive;
  font-style: italic;
}

.quem-somos-img img {
  width: 100%;
}

.quem-somos-texto p {
  font-size: 1.5rem;
  padding: 0 5rem;
  margin: 0;
  text-align: justify;
  margin-bottom: 2rem;
}

// .quem-somos-texto p.linha-cima {
//   padding-bottom: 7rem;
// }



.quem-somos-icons {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.quem-somos-icons figure {
  width: 33%;
  text-align: center;
}

.quem-somos-icons figure figcaption {
  font-size: 1.2rem;
  margin-top: 1rem;
  color: $mainColor;
  font-weight: bolder;
}

/*###############################################################*/
/*########### FIM QUEM-SOMOS ####################################*/
/*###############################################################*/

/*###############################################################*/
/*########### TEN ####################################*/
/*###############################################################*/

.ten-main {
  background-color: #f0a72c;
  padding: 5.5rem;
  width: 100%;
}

.ten {
  align-items: center;
  display: flex;
  justify-content: center;
}

.ten-icons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ten-icons figure {
  text-align: center;
  width: 33%;
  margin: 0;
}

.ten-icons figure figcaption {
  //color: $mainColor;
  color: $corBranco;
  font-size: 1.2rem;
  font-weight: bolder;
  margin-top: 1rem;
}


/*###############################################################*/
/*########### FIM TEN ####################################*/
/*###############################################################*/

/*###############################################################*/
/*################ FRONT-PAGE-QUARTOS ###########################*/
/*###############################################################*/

.frontpage-quartos {
  padding: 5rem 0.5rem 2rem;
  background-color: $bgBrancoAzul;
}

.fp-quartos-main {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  padding: 0 5rem;
  max-width: 1750px;
  margin: 0 auto;
}

.fp-quarto {
  width: 30%;
  position: relative;
  background-color: $corBranco;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  margin-bottom: 3rem;
}

.fp-quarto figure {
  position: relative;
  margin: 0;
}

.fp-quarto figcaption {
  bottom: 10px;
  color: #f0a72c;
  font-family: Caveat, cursive;
  font-size: 1.7rem;
  right: 10px;
  position: absolute;
  z-index: 999;
  background: #fff;
  padding: 0 0.7rem;
}

.filtro-quarto-figure {
  background-color: rgba(0, 0, 0, 0.1);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 9;
}

.fp-quarto-content {
  padding: 1rem 1.5rem;
}

.fp-quarto-nome h3 {
  font-size: 1.1rem;
  padding: 0 0 1rem 0;
  font-weight: 500;
  margin: 0;
}

.fp-quarto .quarto-foto {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  width: 100%;
}

.fp-quarto-detalhes {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  width: 100%;
  font-size: 0.9rem;
  /* text-align: center; */
}

.fp-quarto-detalhes path {
  fill: $cinzentoEscuro;
}

.fp-quarto-detalhes div {
  // width: 30%;
  width: auto;
  padding: 0 0.5rem;
}

.fp-quarto-detalhes span {
  color: $cinzentoEscuro;

  font-weight: 500;
  padding: 0 0.4rem;
}

.fp-quarto-descricao {
  padding: 2.5rem 0;
  //margin-bottom: 4rem;
  margin-bottom: 2rem;
}

.fp-quarto-descricao p {
  margin: 0;
  display: none;
}

.fp-quarto-bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  padding-bottom: 1rem;
}

.fp-quarto-bottom .btn-amarelo {
  width: 70%;
}


/*###############################################################*/
/*################ PEQUENO ALMOÇO ###########################*/
/*###############################################################*/

.pequeno-almoco{
  display: flex;
  width: 100%;
  padding: 3rem 5rem 0 5rem;
  max-width: 1750px;
  margin: 0 auto;
  justify-content: space-between;
}

.pequeno-almoco .p-almoco-foto{
  width: 45%;
}

.pequeno-almoco .p-almoco-txt{
  width: 55%;
}

.pequeno-almoco .p-almoco-foto img{
  width: 100%;
}

.p-almoco-txt .titulo h1{
  padding: 0 0 3rem;
}

.p-almoco-txt{
  padding: 0 6rem;
}

.peq-almoco-itens{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.p-almoco-txt figure{
  align-items: center;
  display: flex;
  position: relative;
  width: 50%;
}

.p-almoco-txt .figcaption-div{
  margin: 0.2rem 0;
  padding-left: 10px;
}

.p-almoco-txt div figcaption{
  font-size: 1.2rem;
  
}

/*###############################################################*/
/*################ FIM PEQUENO ALMOÇO ###########################*/
/*###############################################################*/

// .fp-quarto-bottom p{
//   width: 40%;
//   color: $mainColor;
//   font-family: 'Caveat', cursive;
//   font-size: 2rem;
// }

// .fp-quarto-bottom button{
//   width: 60%;
// }

// .fp-quarto-detalhes figure{
//   text-align: center;
// }

// .fp-quarto-detalhes figcaption{
//   margin: 1rem 0;
// }

/*###############################################################*/
/*############### FIM FRONT-PAGE-QUARTOS ########################*/
/*###############################################################*/

/*###############################################################*/
/*####### FRONT-PAGE-QUARTOS EXEMPLO 2 ##########################*/
/*###############################################################*/

.frontpage-quartos2 {
  padding: 5rem 0.5rem 2rem;
  background-color: $bgBrancoAzul;
}

.frontpage-quartos2 .fp-quartos-main {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  padding: 0 5rem;
  max-width: 1750px;
  margin: 0 auto;
}

.frontpage-quartos2 .fp-quarto2 {
  width: 30%;
  position: relative;
  background-color: $corBranco;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  margin-bottom: 3rem;
  border-radius: 5px;
}

.frontpage-quartos2 .fp-quarto2 figure {
  position: relative;
  margin: 0;
}

.frontpage-quartos2 .fp-quarto-detalhes2 {
  // background: none;
  position: absolute;
  bottom: 15px;
  left: 10px;
  font-family: Nunito, sans-serif;
  color: #fff;
  padding: 0 0.7rem;
  z-index: 999;
}

.frontpage-quartos2 .fp-quarto-detalhes {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  font-size: 0.9rem;
  /* text-align: center; */
}

.frontpage-quartos2 .fp-quarto2 figcaption {
  background: none;
  // bottom: 5px;
  // color: #fff;
  // font-family: Nunito,sans-serif;
  font-size: 2rem;
  // left: 5px;
  // padding: 0 .7rem;
  // position: absolute;
  // z-index: 999;
  font-weight: bold;
  line-height: 1;
}

.frontpage-quartos2 .fp-quarto2 figcaption span {
  font-size: 1rem;
}

.frontpage-quartos2 .filtro-quarto-figure {
  background-color: rgba(0, 0, 0, 0.1);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 9;
}

.frontpage-quartos2 .fp-quarto-content {
  padding: 1rem 1.5rem;
}

.frontpage-quartos2 .fp-quarto-nome h3 {
  font-size: 1.1rem;
  padding: 0.6rem 0;
  font-weight: bold;
  margin: 0;
}

.frontpage-quartos2 .fp-quarto2 .quarto-foto {
  // border-top-left-radius: 6px;
  // border-top-right-radius: 6px;
  width: 100%;
  border-radius: 5px;
}

.frontpage-quartos2 .fp-quarto-detalhes path {
  fill: $corBranco;
}

.frontpage-quartos2 .fp-quarto-detalhes div {
  width: auto;
  padding: 0 0.8rem 0 0;
}

.frontpage-quartos2 .fp-quarto-detalhes span {
  color: $corBranco;

  font-weight: 700;
  padding: 0 0.4rem;
}

.frontpage-quartos2 .fp-quarto-descricao {
  padding: 2.5rem 0;
  margin-bottom: 4rem;
}

.frontpage-quartos2 .fp-quarto-descricao p {
  margin: 0;
}

.frontpage-quartos2 .fp-quarto-bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  padding-bottom: 1rem;
}

.frontpage-quartos2 .fp-quarto-bottom .btn-amarelo {
  width: 70%;
}

.frontpage-quartos2 .figure-gradient:before {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  content: "";
  opacity: 1;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0)),
    color-stop(0%, rgba(0, 0, 0, 0)),
    color-stop(50%, rgba(0, 0, 0, 0)),
    to(rgba(0, 0, 0, 0.75))
  );
  background-image: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.75) 100%
  );
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.75) 100%
  );
  z-index: 1;
  border-radius: 5px;
}

// .fp-quarto-bottom p{
//   width: 40%;
//   color: $mainColor;
//   font-family: 'Caveat', cursive;
//   font-size: 2rem;
// }

// .fp-quarto-bottom button{
//   width: 60%;
// }

// .fp-quarto-detalhes figure{
//   text-align: center;
// }

// .fp-quarto-detalhes figcaption{
//   margin: 1rem 0;
// }

/*###############################################################*/
/*######## FIM FRONT-PAGE-QUARTOS EXEMPLO 2 #####################*/
/*###############################################################*/

/*###############################################################*/
/*####### FRONT-PAGE-QUARTOS EXEMPLO 3 ##########################*/
/*###############################################################*/

.frontpage-quartos3 {
  padding: 5rem 0.5rem 2rem;
  background-color: $bgBrancoAzul;
}

.frontpage-quartos3 .fp-quartos-main {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  padding: 0 5rem;
  max-width: 1750px;
  margin: 0 auto;
}

.frontpage-quartos3 .fp-quarto2 {
  width: 30%;
  position: relative;
  background-color: $corBranco;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  margin-bottom: 3rem;
}

.frontpage-quartos3 .fp-quarto2 figure {
  position: relative;
  margin: 0;
}

.frontpage-quartos3 .fp-quarto-detalhes2 {
  // background: none;
  position: absolute;
  bottom: 15px;
  left: 10px;
  font-family: Nunito, sans-serif;
  color: #fff;
  padding: 0 0.7rem;
  z-index: 999;
}

.frontpage-quartos3 .fp-quarto-detalhes {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  font-size: 0.9rem;
  margin-top: 0.8rem;
  /* text-align: center; */
}

.frontpage-quartos3 .fp-quarto2 figcaption {
  background: none;
  // bottom: 5px;
  // color: #fff;
  // font-family: Nunito,sans-serif;
  font-size: 2rem;
  // left: 5px;
  // padding: 0 .7rem;
  // position: absolute;
  // z-index: 999;
  font-weight: bold;
  line-height: 1;
}

.frontpage-quartos3 .fp-quarto2 figcaption span {
  font-size: 1rem;
}

.frontpage-quartos3 .filtro-quarto-figure {
  background-color: rgba(0, 0, 0, 0.1);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 9;
}

.frontpage-quartos3 .fp-quarto-content {
  padding: 1rem 1.5rem;
}

.frontpage-quartos3 .fp-quarto-nome h3 {
  font-size: 1.1rem;
  padding: 2rem 1rem;
  font-weight: bold;
  margin: 0;
}

.frontpage-quartos3 .fp-quarto2 .quarto-foto {
  // border-top-left-radius: 6px;
  // border-top-right-radius: 6px;
  width: 100%;
  border-radius: 6px;
}

.frontpage-quartos3 .fp-quarto-detalhes path {
  fill: $corBranco;
}

.frontpage-quartos3 .fp-quarto-detalhes div {
  width: auto;
  padding: 0 0.8rem 0 0;
}

.frontpage-quartos3 .fp-quarto-detalhes span {
  color: $corBranco;

  font-weight: 700;
  padding: 0 0.4rem;
}

.frontpage-quartos3 .fp-quarto-descricao {
  padding: 2.5rem 0;
  margin-bottom: 4rem;
}

.frontpage-quartos3 .fp-quarto-descricao p {
  margin: 0;
}

.frontpage-quartos3 .fp-quarto-bottom {
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
  text-align: center;
  padding-bottom: 1rem;
}

.frontpage-quartos3 .fp-quarto-bottom .btn-amarelo {
  width: 30%;
}

.frontpage-quartos3 .figure-gradient:before {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  content: "";
  opacity: 1;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0)),
    color-stop(0%, rgba(0, 0, 0, 0)),
    color-stop(60%, rgba(0, 0, 0, 0)),
    to(rgba(0, 0, 0, 0.75))
  );
  background-image: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0.75) 100%
  );
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0.75) 100%
  );
  z-index: 1;
}

// .fp-quarto-bottom p{
//   width: 40%;
//   color: $mainColor;
//   font-family: 'Caveat', cursive;
//   font-size: 2rem;
// }

// .fp-quarto-bottom button{
//   width: 60%;
// }

// .fp-quarto-detalhes figure{
//   text-align: center;
// }

// .fp-quarto-detalhes figcaption{
//   margin: 1rem 0;
// }

/*###############################################################*/
/*######## FIM FRONT-PAGE-QUARTOS EXEMPLO 3 #####################*/
/*###############################################################*/

/*###############################################################*/
/*################## COMODIDADES ################################*/
/*###############################################################*/

.comodidades-main {
  width: 100%;
  display: flex;
  background: url(../../img/piscina3_reduced.jpg) center center no-repeat;
  padding: 5rem 5rem;
  justify-content: flex-end;
}

.comodidades .titulo h3 {
  color: #f0a72c;
  font-family: Caveat, cursive;
  // font-size: 4.5rem;
  font-size: 3rem;
  letter-spacing: 2.5px;
  padding: 0 0 2rem;
  text-align: center;
  width: 100%;
}

.comodidades h4 {
  padding: 0;
  width: 80%;
  margin: 0 auto 3rem;
  font-size: 1.1rem;
  font-weight: 100;
  text-align: justify;
}

.comodidades {
  background-color: #fff;
  padding: 2rem;
  max-width: 40%;
}

.comodidades-content {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 2.5rem;
}

.comodidades-content-left,
.comodidades-content-right {
  // width: 50%;
}

.comodidades-content-left div,
.comodidades-content-right div {
  padding: 1.5rem 0;
}

.comodidades-content img {
  width: 50px;
}

.comodidades-content span {
  font-size: 1.4rem;
  padding-left: 0.6rem;
  font-weight: 400;
  color: $mainColor;
}

/*###############################################################*/
/*############### FIM COMODIDADES ###############################*/
/*###############################################################*/

/*###############################################################*/
/*############### COMODIDADES 2 ###############################*/
/*###############################################################*/

// COMODIDADE SUITES

.comodidades-suites-section{
  background-color: #f7f8f9;
  padding: 0 0.5rem 4rem;
}

.comodidades-suites{
  display: flex;
  width: 100%;
  padding: 3rem 5rem 0 5rem;
  max-width: 1750px;
  margin: 0 auto;
  justify-content: space-between;
}

.comodidades-suites .com-suites-txt{
  width: 55%;
}

.comodidades-suites .com-suites-foto{
  width: 55%;
}

.comodidades-suites .com-suites-foto img{
  width: 100%;
}

.com-suites-txt .titulo h1{
  padding: 0 0 3rem;
}

.com-suites-txt{
  padding: 0;
}



.com-suites-itens{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
}

.com-suites-txt figure{
  align-items: center;
  display: flex;
  position: relative;
  width: 33%;
  margin-bottom: 1.5rem;
}

.com-suites-itens figure img{
  width: 1.5rem;
}

.com-suites-txt .figcaption-div{
  margin: 0.2rem 0;
  padding-left: 12px;
}

.com-suites-txt div figcaption{
  font-size: 1.1rem;
  
}




/*###############################################################*/
/*############### FIM COMODIDADES 2 ###############################*/
/*###############################################################*/

/*###############################################################*/
/*############ FRONT-RESERVA-PISOS ##############################*/
/*###############################################################*/

.front-reserva-piso-main {
  padding: 5.5rem;
  background-color: $mainColor;
  width: 100%;
}

.front-reserva-piso {
  display: flex;
  justify-content: center;
  align-items: center;
}

.front-reserva-piso aside div {
  font-family: Caveat, cursive;
  color: #fff;
  font-size: 3.5rem;
  letter-spacing: 2.5px;
  margin-right: 5rem;
}

.front-reserva-piso aside .btn-amarelo {
  font-size: 1.5rem;
  border: 2px solid #fff;
  padding: 0.6rem 4rem;
}

/*###############################################################*/
/*########## FIM FRONT-RESERVA-PISOS ############################*/
/*###############################################################*/


/*###############################################################*/
/*########## FRONT-GUESTHOUSE ############################*/
/*###############################################################*/

.frontpage-guesthouse {
  background-color: #ffffff;
  padding: 5rem 0.5rem 2rem;

}

.guesthouse-main{
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  //max-width: 1750px;
  padding: 0 5rem 0;
}

.guesthouse-img{
  width: 55%;
}

.guesthouse-img figure{
  position: relative;
}

.guesthouse-img figcaption {
  background: #fff;
  bottom: 10px;
  color: #f0a72c;
  font-family: Caveat,cursive;
  font-size: 1.7rem;
  padding: 0 0.7rem;
  position: absolute;
  right: 10px;
  z-index: 999;
}

.guesthouse-img img{
  width: 100%;
}

.guesthouse-texto {
  width: 45%;
  padding: 0 6rem;
}

.guesthouse-texto p.linha-cima {
  padding: 5rem 0;
}

.guesthouse-texto p {
  font-size: 1.3rem;
  margin: 0;
  padding: 0 1rem;
  text-align: justify;
}

.guesthouse-btn{
  width: 50%;
  margin: 0 auto;
}

.guesthouse-detalhes {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  width: 100%;
  font-size: 0.9rem;
  /* text-align: center; */
}

.guesthouse-detalhes path {
  fill: $cinzentoEscuro;
  font-size: 2rem;
}

.guesthouse-detalhes div {
  // width: 30%;
  width: auto;
  padding: 0.5rem 4rem 0 0;
}

.guesthouse-detalhes svg{
  font-size: 1.8rem;
}

.guesthouse-detalhes span {
  color: $cinzentoEscuro;
  font-size: 1.6rem;
  font-weight: 100;
  padding: 0 0.7rem;
}

/*###############################################################*/
/*########## FIM FRONT-GUESTHOUSE ############################*/
/*###############################################################*/


/*###############################################################*/
/*########## FRONT-GUESTHOUSE ############################*/
/*###############################################################*/

.frontpage-guesthouse2 {
  background-color: #ffffff;
  padding: 5rem 0 2rem;

}

.frontpage-guesthouse2 .titulo h1{
  padding: 0 0 3rem;
}

.frontpage-guesthouse2 .sub-titulo{
  padding: 0 2rem 3rem;
  width: 60%;
  margin: 0 auto;
}

.frontpage-guesthouse2 .sub-titulo p{
  font-size: 1.3rem;
  text-align: center;
  line-height: 2;
}

.guesthouse-main2{
  //align-items: flex-start;
  //display: flex;
  //flex-wrap: wrap;
  width: 100%;
  position: relative;
  //height: 100vh;
  //max-width: 1750px;
  //padding: 0 5rem 0;
  z-index: 99;
}

.guesthouse-img2{
  width: 100%;
}

.guesthouse-img2 figure{
  position: relative;
}

// .filtro-guesthouse-content2 {
//   background-color: rgba(0,0,0,.35);
//   background-image: linear-gradient(to right, #4d4d4d , #cad6d7);
//   height: 100%;
//   position: absolute;
//   width: 100%;
//   z-index: 9;
// }


.guesthouse-content2{
  //left: 20%;
  //position: absolute;
  //text-align: center;
  //top: 40%;
  //transform: translate(-50%,-50%);
  width: 100%;
  z-index: 999;
  padding: 18rem 0 8rem;
  text-align: center;
}

.guesthouse-content2 p{
  color: #fff;
  font-size: 3rem;
  margin-bottom: 2rem;
}

.guesthouse-content-preco2{
  background: #fff;
  bottom: 10px;
  color: #f0a72c;
  font-family: Caveat,cursive;
  font-size: 1.7rem;
  padding: 0 0.7rem;
  position: absolute;
  right: 10px;
  z-index: 999;
}

.guesthouse-content-preco2 p{
  margin: 0;
}

.guesthouse-img2 img{
  width: 100%;
}

.guesthouse-texto2 {
  width: 45%;
  padding: 0 6rem;
}

.guesthouse-texto2 p.linha-cima {
  padding: 5rem 0;
}

.guesthouse-texto2 p {
  font-size: 1.3rem;
  margin: 0;
  padding: 0 1rem;
  text-align: justify;
}

.guesthouse-btn2{
  width: 20%;
  margin: 0 auto;
  padding: 3rem 0 0;
}

.guesthouse-detalhes2 {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  width: 100%;
  font-size: 0.9rem;
  /* text-align: center; */
  padding: 1rem 3rem 0.5rem;
}

.guesthouse-detalhes2 path {
  fill: $corBranco;
  //font-size: 1.8rem;
  fill-opacity: 1;
}

.guesthouse-detalhes2 div {
  // width: 30%;
  width: auto;
  padding: 0.5rem 4rem 0 0;
}

.guesthouse-detalhes2 svg{
  font-size: 1.5rem;
}

.guesthouse-detalhes2 span {
  color: $corBranco;
  font-size: 1.3rem;
  font-weight: 100;
  padding: 0 0.7rem;
}

/*###############################################################*/
/*########## FIM FRONT-GUESTHOUSE 2 ############################*/
/*###############################################################*/


/*###############################################################*/
/*############### COMODIDADES GUESTHOUSE ###############################*/
/*###############################################################*/

// COMODIDADE SUITES

.comodidades-guesthouse-section{
  background-color: $corBranco;
  padding: 0 0.5rem 4rem;
}

// .comodidades-guesthouse{
//   display: flex;
//   width: 100%;
//   padding: 3rem 5rem 0 5rem;
//   max-width: 1750px;
//   margin: 0 auto;
//   justify-content: space-between;
// }

.comodidades-guesthouse {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1750px;
  padding: 1.5rem 5rem 0;
  width: 100%;
  flex-wrap: wrap;
}

.comodidades-guesthouse .com-guesthouse-txt{
  //width: 55%;
  width: 100%;
}

.comodidades-guesthouse .com-guesthouse-foto{
  //width: 55%;
  width: 100%;
}

.comodidades-guesthouse .com-guesthouse-foto img{
  width: 100%;
}

.com-guesthouse-txt .titulo h1{
  padding: 0 0 3rem;
}

.com-guesthouse-txt{
  padding: 0;
}



// .com-guesthouse-itens{
//   display: flex;
//   flex-wrap: wrap;
//   width: 100%;
//   justify-content: start;
// }

.com-guesthouse-itens {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 0 8rem 3rem;
}

.com-guesthouse-txt figure{
  align-items: center;
  display: flex;
  position: relative;
  width: 33%;
  margin-bottom: 1.5rem;
}

.com-guesthouse-itens figure img{
  width: 1.5rem;
}

.com-guesthouse-txt .figcaption-div{
  margin: 0.2rem 0;
  padding-left: 12px;
}

.com-guesthouse-txt div figcaption{
  font-size: 1.1rem;
  
}




/*###############################################################*/
/*############### FIM COMODIDADES GUESTHOUSE ###############################*/
/*###############################################################*/

/*###############################################################*/
/*################# SEGURANCA ###################################*/
/*###############################################################*/

.seguranca-main {
  display: flex;
  width: 100%;
  padding: 5rem 10rem;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
}

.seguranca-img,
.seguranca-texto {
  width: 50%;
}

.seguranca-texto {
  padding: 0 5rem 0 0;
}

.seguranca-img img {
  width: 100%;
}

.seguranca-texto p.linha-cima {
  padding-bottom: 7rem;
}

.seguranca-icons {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.seguranca-icons figure {
  width: 33%;
  text-align: center;
}

.seguranca-icons figure figcaption {
  font-size: 1.2rem;
  margin-top: 1rem;
  color: $mainColor;
  font-weight: bolder;
}

/*###############################################################*/
/*############### FIM SEGURANCA #################################*/
/*###############################################################*/

/*###############################################################*/
/*################## GALERIA ####################################*/
/*###############################################################*/

.front-galeria-main {
  padding: 5rem 0.5rem 2rem;
}

.front-galeria {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 1750px;
  padding: 0 5rem;
  width: 100%;
}

.front-galeria aside {
  box-shadow: 0 5px 15px rgb(0 0 0 / 5%);
  margin-bottom: 3rem;
  position: relative;
  width: 27%;
}

.front-galeria aside figure {
  margin: 0;
}

.front-galeria aside img {
  width: 100%;
}

.galeria-link {
  text-align: center;
  padding: 1.5rem;
}

.galeria-link .btn-amarelo {
  width: 25%;
  font-size: 1.1rem;
}

a[class*="lightcase-icon-"].lightcase-icon-close {
  //top:75px;
}

#lightcase-info #lightcase-title,
#lightcase-info #lightcase-caption {
  font-size: 1.2rem;
}

/*###############################################################*/
/*################ FIM GALERIA ##################################*/
/*###############################################################*/

/*###############################################################*/
/*################ MAPA ##################################*/
/*###############################################################*/

#mapa{
  padding: 1rem 5rem;
}



/*###############################################################*/
/*################ FIM MAPA ##################################*/
/*###############################################################*/

/*###############################################################*/
/*################# PRODUTO DESTAQUE ############################*/
/*###############################################################*/

.produto-destaque-main {
  background-color: $corBranco;
  padding: 4.4rem 0;
}

.produtos-destaque-block {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.produtos-destaque-block .produto-img,
.produtos-destaque-block .produto-txt {
  width: 40%;
}

.produtos-destaque-block .produto-txt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //   width: 30%;
  //   box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  //   margin-bottom: 4rem;
}

.produtos-destaque-block .produto-txt .section-title {
  position: relative;
}

.produtos-destaque-block .produto-txt .section-title h1:after {
  width: 15%;
}

.section-txt p {
  font-size: 1.2rem;
  color: $cinzentoClaro;
  font-weight: 700;
}

/*###############################################################*/
/*################ FIM PRODUTO DESTAQUE #########################*/
/*###############################################################*/

/*###############################################################*/
/*#################### VS #######################################*/
/*###############################################################*/
.vs-main {
  background-color: $mainColor;
  padding: 5rem 3rem;
}

.vs-intro {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.vs-titulo {
  padding: 0 2rem 0 0;
}

.vs-titulo h2 {
  font-size: 2.3rem;
  font-style: italic;
  font-weight: 700;
  color: #fff;
}

.vs-button-aside {
}

/*###############################################################*/
/*################# FIM VS ######################################*/
/*###############################################################*/

/*###############################################################*/
/*################ MARCAS #######################################*/
/*###############################################################*/

.marcas-content-front {
  width: 100%;
}

.marca-aside {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 2rem 0;
}

.marca-aside div {
  width: 50%;
}

.marca-img {
  text-align: center;
}

.marca-img img {
  width: 80%;
}

.marcas-content-front .marca-conteudo h1 {
  padding: 1rem;
  text-align: center;
  width: 100%;
  color: $mainColor;
}

/*###############################################################*/
/*############### FIM MARCAS ####################################*/
/*###############################################################*/

/*###############################################################*/
/*############# MARCAS SIMPLES ##################################*/
/*###############################################################*/

.marcas-main {
  background-color: #fff;
  padding: 4.4rem 3rem 2.5rem;
}

.marcas-content {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.marca-single {
  display: flex;
  flex-direction: column;
  width: 26%;
  //   box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  margin-bottom: 4rem;
}

.marca-single img {
  width: 80%;
  margin: 0 auto;
}

.marca-single h1 {
  padding: 1rem;
  text-align: center;
  width: 100%;
  color: $mainColor;
}

.marca-img {
  text-align: center;
}

.marcas-content-front .marca-conteudo h1 {
  padding: 1rem;
  text-align: center;
  width: 100%;
  color: $mainColor;
}

/*###############################################################*/
/*############ FIM MARCAS SIMPLES ###############################*/
/*###############################################################*/

/*###############################################################*/
/*########## RESPONDEMOS / PARALLAX #############################*/
/*###############################################################*/

.parallax-section {
  /*  padding: 70px 100px;
    background-color: $maincolor;*/
}

.respondemos .parallax-intro h2 {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  // padding: 4rem;
  font-size: 3.1rem;
  font-weight: 900;
  z-index: 9;
  position: relative;
  line-height: 1.5;
  font-style: italic;
  color: $mainColor;
}

.parallax-parallax {
  color: $corBranco;
  background-color: #000;
  /* text-align: center; */
  position: relative;
  overflow: hidden;
  /*height: 80vh;*/
  height: 90vh;
}

.fundo-parallax {
  position: absolute;
  background: url(../../img/parallax/log900_700l.png) left center no-repeat;
  background-size: cover;
  width: 100%;
  height: 150%;
  top: 0;
  z-index: 1;
  opacity: 0.9;
}

.parallax-content {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
}

.parallax-esquerda,
.parallax-direita {
  position: absolute;

  padding: 6rem 4rem;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.parallax-esquerda {
  left: 25%;
  width: 40%;
}

.parallax-direita {
  left: 75%;
  width: 60%;
}

.parallax-intro {
  position: relative;
  text-align: center;
  z-index: 9;
  padding: 0 3rem 6rem 0;
}

.parallax-intro h2 {
  text-align: center;
  color: $corBranco;
  font-weight: 300;
}

.parallax-intro h4 {
  text-align: center;
  color: $corBranco;
  font-weight: 300;
  padding-bottom: 90px;
  font-size: 2rem;
  margin: 0;
}

.parallax-intro h4:after {
  content: "";
  width: 5%;
  height: 3px;
  background-color: $corBranco;
  display: block;
  margin-top: 30px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.parallax-intro p {
  font-size: 1.1rem;
  font-weight: 200;
  text-align: center;
}

.contador-base {
  z-index: 9;
}

.contador {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: baseline;
  line-height: 1;
}

.cont1 {
  margin-bottom: 2rem;
}

.contador span,
.contador p {
  color: $mainColor;
}

.contador p {
  margin-left: 1rem;
}

.num-cont {
  font-size: 4rem;
  font-weight: 900;
}

.contador p {
  font-size: 1.7rem;
}

/*###############################################################*/
/*######## FIM RESPONDEMOS / PARALLAX ###########################*/
/*###############################################################*/

/*###############################################################*/
/*################ CERTIFICADOS #################################*/
/*###############################################################*/

.certificados-main {
  background-color: $whiteSmoke;
  padding: 4.4rem 6rem;
}

.certificados-content-front {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.certificados-content-front .certificado-front {
  display: flex;
  flex-direction: column;
  width: 15%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
}

.certificado-bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.certificados-content-front .certificado-front img {
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.certificados-content-front .certificado-front h1 {
  padding: 1rem;
  text-align: center;
  width: 100%;
  color: $mainColor;
}

/*###############################################################*/
/*############### FIM CERTIFICADOS ##############################*/
/*###############################################################*/

/*###############################################################*/
/*############### ENTREEMCONTACTO ###############################*/
/*###############################################################*/

.entreemcontacto-main {
  background-color: $corBranco;
  padding: 4.4rem 6rem;
}

.certificados-content-front {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.entreemcontacto-content-front {
  position: relative;
  width: 60%;
  // height:100vh;
  // background: url(../../img/background.jpg)center center no-repeat;
  // background-size: cover;
  padding: 5rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  margin: 0 auto;
}

// .filtro-contacto{
//     background-color: #000;
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     opacity: 0.7;
// }

.formulario {
  position: relative;
}

.formulario label {
  // color:$corBranco;
}

//FORM
.form-error {
  color: red;
}

.form-error {
  color: green;
}

.formulario .input-error {
  box-shadow: 0 0 5px red;
}

/*###############################################################*/
/*############## FIM ENTREEMCONTACTO ############################*/
/*###############################################################*/
