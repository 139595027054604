
$footercolor: #6d6e6a;
$corBranco: #ffffff;
$corPreto: #000;
$whiteSmoke: whitesmoke;

$cinzento: #636363;
$cinzentoClaro:#a6acb5;
$cinzentoEscuro: #3b4249;
$cinzentoEscuro2: #6a6a6a;

$mainColor: #f0a72c;
$bgBrancoAzul: #f7f8f9;