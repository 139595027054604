.button-div{
	text-align: center;
}

//=== Set button colors
// If you wonder why use Sass vars or CSS custom properties...
  // Make a map with the class names and matching colors
$colors: (
  fill: #a972cb,
  pulse: #ef6eae, 
  close: #ff7f82, 
  raise: #ffa260, 
  up: #e4cb58, 
  // slide-left-btn: #8fc866, 
  slide-left-btn: #e4cb58,
  slide-archive: #e4cb58,
  vs-button:#fff,
  offset: #19bc8b
);

// Sass variables compile to a static string; CSS variables are dynamic and inherited
  // Loop through the map and set CSS custom properties using Sass variables
@each $button, $color in $colors {
  .#{$button} {
    --color: #{$color};
    --hover: #{adjust-hue($color, 45deg)};
  }
}

// Now every button will have different colors as set above. We get to use the same structure, only changing the custom properties.
.slide-left-btn {  
  color: var(--color);
  transition: 0.25s;
  
  &:hover,
  &:focus { 
    border-color: var(--hover);
    color: #fff;
  }
}

.vs-button {  
  color: var(--color);
  transition: 0.25s;
  
  &:hover,
  &:focus { 
    border-color: var(--hover);
    color: $mainColor;
  }
}

.slide-archive {  
  color: var(--color);
  transition: 0.25s;
  
  &:hover,
  &:focus { 
    border-color: var(--hover);
    color: #fff;
  }
}


// Basic button styles
.slide-left-btn {
  background: none;
  border: 2px solid;
  font: inherit;
  line-height: 1;
  margin: 0.5em;
  padding: 0.7em 2em;
  font-size: 1.1rem;
}

.vs-button {
  background: none;
  border: 2px solid;
  font: inherit;
  line-height: 1;
  margin: 0.5em;
  padding: 1em 2em;
  font-size: 1.2rem;
}

.slide-archive {
  background: none;
  border: 3px solid;
  font: inherit;
  line-height: 1;
  margin: 0.5em;
  padding: 1em 2em;
  font-size: 1.2rem;
}

// And from the left
.slide-left-btn:hover,
.slide-left-btn:focus {
  box-shadow: inset 8.5em 0 0 0 var(--hover);
}

// And from the left
.vs-button:hover,
.vs-button:focus {
  box-shadow: inset 14.5em 0 0 0 var(--hover);
}


// And from the left
.slide-archive:hover,
.slide-archive:focus {
  box-shadow: inset 25.5em 0 0 0 var(--hover);
}

