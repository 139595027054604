// #####################################################################
// ##################  PRODUTO  ########################################
// #####################################################################

.produto-main {
  width: 100%;
  padding: 2rem;
}

.produto-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.produto-content .produto-texto,
.produto-content .produto-img {
  width: 50%; 
}

.produto-texto {
  padding: 0 1.5rem;
}

.produto-texto h3 {
  color: $mainColor;
  margin-bottom: 2rem;
}

.calendario h3 {
  color: $mainColor; 
  margin: 3rem 0 2rem;
} 

.calendario table th,
.calendario table td {
  border-left: 1px solid $cinzentoClaro;
  padding: 0.5rem;
}

.calendario table th {
  color: $cinzentoClaro;
}

.semestre-1 div,
.semestre-2 div {
  border-left: 1px solid #a6acb5;
  padding: 0.2rem 0 0;
  text-align: center;
}

.semestre-1 div:nth-child(1) {
  border-left: none;
}

.semestre-1 div p,
.semestre-2 div p {
  color: #a6acb5;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 0.8rem;
}

.produto-img {
  text-align: center;
}

.produto-img img {
  //   width: 100%;
  width: 82%;
}

.outros-produtos-main {
  position: relative;
  padding: 0 5rem 4rem;
  margin-top: 2rem;
  background-color: $whiteSmoke;
  z-index: 99;
}

.outros-produtos-title h2 {
  text-align: center;
  color: $mainColor;
  font-weight: 300;
  padding-bottom: 70px;
  padding: 2rem 0 5.5rem;
  font-size: 2.5rem;
}
.outros-produtos-title h2:after {
  content: "";
  width: 5%;
  height: 3px;
  background-color: $mainColor;
  display: block;
  margin-top: 30px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.produtos-slide h3 {
  font-size: 1.4rem;
  color: $mainColor;
  text-align: center;
  text-decoration: none;
  padding: 1rem;
}

// #####################################################################
// #################  FIM PRODUTO  #####################################
// #####################################################################
